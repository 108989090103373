@use "breakpoints" as *;

@mixin breakpoint($size) {
  $breakpoint: map-get($breakpoints, $size);

  @if ($breakpoint) {
    @media (max-width: $breakpoint) {
      @content;
    }
  } @else {
    @media (max-width: $size) {
      @content;
    }
  }
}

@mixin breakpoint-up($size) {
  $breakpoint: map-get($breakpoints, $size);

  @if ($breakpoint) {
    @media (min-width: $breakpoint) {
      @content;
    }
  } @else {
    @media (min-width: $size) {
      @content;
    }
  }
}

@mixin pseudo(
  $loc: after,
  $content: "",
  $display: block,
  $full: true,
  $pos: absolute,
  $top: 0,
  $right: 0,
  $bottom: 0,
  $left: 0,
  $both: false
) {
  @if $both == false {
    &::#{$loc} {
      content: $content;
      display: $display;
      position: $pos;
      top: $top;
      left: $left;

      @if ($full == true) {
        right: $right;
        bottom: $bottom;
      }

      @content;
    }
  } @else {
    &::before,
    &::after {
      content: $content;
      display: $display;
      position: $pos;
      top: $top;
      left: $left;

      @if ($full == true) {
        right: $right;
        bottom: $bottom;
      }

      @content;
    }
  }
}
