@use "../abstracts" as *;

.card {
  mask: url("../img/card-shape-base.png") no-repeat center / contain;
  aspect-ratio: 1 / 1.17;

  justify-content: center;
  transition: background-color 0.4s;
  background-color: var(--clr-neutral-40);
  text-align: center;
  color: var(--clr-neutral-100);

  &:hover,
  &--actived {
    background-color: hsl(
      var(--clr-primary-hue) var(--clr-primary-saturation) var(--clr-primary-light) / 0.8
    );

    .btn {
      border-color: var(--clr-neutral-30);
      background-color: var(--clr-neutral-30);
    }
  }

  &--actived {
    &:hover {
      background-color: var(--clr-neutral-40);

      .btn {
        border-color: var(--clr-primary);
        background-color: var(--clr-primary);
      }
    }
  }

  &-img-top {
    width: rem(70);
    height: rem(70);
    object-fit: contain;
    margin: 0 auto;
  }

  &-body {
    flex: unset;
  }

  &-title {
    margin-bottom: 0.25em;
    font-size: rem(33);
  }

  &-text {
    margin-bottom: 0.75em;
    font-size: rem(23);
    line-height: 1.1;
  }

  @include breakpoint(xxl) {
    &-body {
      padding: 0.75rem;
    }

    &-title {
      font-size: rem(22);
    }

    &-text {
      font-size: rem(18);
    }
  }

  @include breakpoint(xsm) {
    &-body {
      padding: 0.5rem;
    }

    &-title {
      font-size: rem(19);
    }

    &-text {
      font-size: rem(16);
    }
  }
}
