@use "../abstracts" as *;

.s-services {
  background: url("../img/services-bg.jpg") no-repeat center / cover;

  .container-xxl {
    @extend %col-main-container;
  }

  &__main-img {
    @extend %col-main-img;

    pointer-events: none;
    user-select: none;
  }

  .swiper-button-prev {
    left: calc(var(--sx) * 0.5);
    transform: translate3d(-50%, 0, 0);
  }

  .swiper-button-next {
    right: calc(var(--sx) * 0.5);
    transform: translate3d(50%, 0, 0);
  }

  .swiper-pagination {
    --space: 1rem;

    width: max-content;
    margin: 0 calc(var(--space) * -1);
    bottom: 4rem;
    left: 50%;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    width: auto;
    height: auto;
    position: relative;
    margin: 0 var(--space);
    border-radius: 0;
    background: none;
    opacity: 1;
    font-size: rem(27);
    font-weight: 700;
    color: var(--clr-neutral-100);

    &:where(:not(:last-child))::after {
      content: "-";
      display: block;
      position: absolute;
      top: 50%;
      right: calc(var(--space) * -1);
      transform: translate3d(50%, -50%, 0);
      color: var(--clr-neutral-100);
      pointer-events: none;
    }
  }

  .swiper-pagination-bullet-active {
    color: var(--clr-primary) !important;
  }

  @include breakpoint(xl) {
    .swiper-slide > * {
      padding: 0 2rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 32%;
    }

    .swiper-pagination {
      margin: 0;
      transform: translate3d(-50%, 0, 0);
    }
  }

  @include breakpoint(sm) {
    .swiper-pagination {
      bottom: 0;
    }
  }
}
