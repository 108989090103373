@media print {
  .header {
    position: absolute;
  }

  .main-swiper {
    height: auto;
    overflow: unset;

    &__pagination,
    &__next {
      display: none;
    }

    &__wrapper {
      all: unset;
    }
  }

  .whatsapp {
    position: absolute;
  }
}
