@use "../abstracts" as *;

.s-about {
  background: var(--clr-primary) url("../img/primary-bg.png") no-repeat center / cover;

  .container-xxl {
    @extend %col-main-container;
  }

  &__main-img {
    @extend %col-main-img;
  }

  &__brand {
    font-weight: 700;
    font-size: rem(28);

    img {
      // width: 6rem;
      height: 6rem;
      object-fit: contain;
    }
  }

  @include breakpoint(xxl) {
    &__brand {
      font-size: rem(20);

      img {
        // width: 4.5rem;
        height: 4.5rem;
      }
    }
  }
}
