@use "../abstracts" as *;

.l-footer {
  height: var(--footer-height);
  background-color: var(--clr-neutral-0);
  font-size: rem(21);
  text-align: center;
  color: var(--clr-neutral-92);

  .container-xxl {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  @include breakpoint(xxl) {
    font-size: rem(16);

    .container-xxl {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  @include breakpoint(sm) {
    height: auto;
  }
}
