.dark {
  color: var(--clr-neutral-100);
}

.light {
  color: var(--clr-neutral-10);
}

.clr-primary {
  color: var(--clr-primary);
}
