@use "../abstracts" as *;

section {
  display: flex;
  align-items: center;

  > * {
    max-width: 100%;
  }
}

img,
svg,
video {
  max-width: 100%;
}

.swiper {
  &-slide {
    overflow: hidden;
  }

  &-button {
    &-prev,
    &-next {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &:active {
        background-color: rgba(225, 225, 225, 0.15);
      }

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        mask: url("../img/icon-next.svg") no-repeat center / contain;
        background-color: var(--clr-neutral-100);
      }
    }

    &-prev {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &-horizontal {
    .swiper-scrollbar {
      position: unset;
    }
  }

  .swiper-scrollbar {
    max-width: 20rem;
    height: 4px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--clr-neutral-30);

    &-drag {
      background-color: #c7cdd9;
    }
  }
}

@include breakpoint(1920px) {
  section {
    > * {
      // max-width: 100% !important;
      width: 100%;
    }
  }
}

@include breakpoint(sm) {
  .swiper {
    &-button {
      &-prev,
      &-next {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
