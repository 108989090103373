@use "../abstracts" as *;

.s-experience {
  position: relative;
  background: var(--clr-primary) url("../img/primary-bg.png") no-repeat center / cover;

  .container-xxl {
    position: relative;
  }

  &__cards {
    gap: 2rem 1rem;
  }

  .card {
    max-width: rem(500);
    margin: 0 auto;
    padding: 3rem;
    background-color: rgba(235, 235, 235, 0.8);
    color: #000;

    &-body {
      padding: 0;
    }

    &-title {
      margin-bottom: 0.05em;
      font-size: rem(45);
    }

    &-text {
      margin: 0;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: block;
    margin: 0;
    position: relative;
    inset: 0;
  }

  .swiper-button-disabled {
    display: none;
  }

  @include breakpoint(xxl) {
    .card {
      max-width: rem(400);

      &-title {
        font-size: rem(38);
      }

      &-text {
        font-size: rem(17);
      }
    }
  }

  @include breakpoint(xl) {
    .card {
      width: 28rem;
    }
  }

  @include breakpoint(sm) {
    .card {
      width: 100%;
    }
  }

  @include breakpoint(xsm) {
    .card {
      padding: 1rem 2rem;
      mask: unset;
      aspect-ratio: unset;
      border-radius: 2rem;

      &-title {
        font-size: rem(30);
      }
    }
  }
}
