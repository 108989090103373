@use "../abstracts" as *;

// .l-main {
// height: 100vh;
// overflow-y: scroll;
// scroll-snap-type: y mandatory;
// }

.main-swiper {
  height: 100vh;

  &__next {
    width: 2.5rem;
    height: 2.5rem;
    right: unset;
    top: unset;
    left: 50%;
    bottom: 1.25rem;
    transform: translate3d(-50%, 0, 0);

    &--end {
      bottom: calc(var(--footer-height) + 0.5rem);
    }

    &::after {
      mask-image: url("../img/icon-next-section.svg");
    }
  }

  &__pagination {
    .swiper {
      &-pagination {
        &-bullet {
          display: grid !important;
          place-items: center;
          width: 2rem;
          height: 2rem;
          position: relative;
          // mask: url("../img/card-shape-wide.svg") no-repeat center / contain;
          background-color: #4d4d4d;
          opacity: 1;
          font-size: rem(20);
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 700;
          line-height: 1;
          color: #fff;

          &-active {
            background-color: var(--clr-primary);
            color: #fff;
          }

          &:hover {
            .swiper-pagination-label {
              opacity: 1;
            }
          }
        }

        &-label {
          opacity: 0;
          padding-right: 0.5rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(-100%, -50%, 0);
          font-size: rem(18);
          font-family: Arial;
          font-weight: 700;
          color: #fff;
          text-transform: uppercase;
          white-space: nowrap;
          pointer-events: none;
        }
      }
    }
  }
}

section {
  min-height: 100vh;
  padding-top: var(--navbar-height);
  // scroll-snap-align: start;
}

@media screen and (max-height: rem(666)), (max-width: rem(1199)) {
  .main-swiper {
    height: auto;
    overflow: unset;

    &__pagination,
    &__next {
      display: none;
    }

    &__wrapper {
      all: unset;

      > .swiper-slide {
        height: auto !important;
      }
    }
  }
}
