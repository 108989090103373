@use "mixins" as m;
@use "functions" as f;

%col-main-img {
  margin-right: auto;
  padding-right: 2rem;
  object-fit: contain;

  @include m.breakpoint(xxl) {
    max-height: f.rem(525);
  }

  @include m.breakpoint(xl) {
    max-width: f.rem(350);
    max-height: f.rem(350);
    margin-left: auto;
    padding: 0;
  }

  @include m.breakpoint(sm) {
    max-width: f.rem(275);
    max-height: f.rem(275);
  }
}

%col-main-container {
  --sx: 6rem;

  max-width: f.rem(1575);
  padding: 0 var(--sx);
  position: relative;

  @include m.breakpoint(xxl) {
    --sx: 5rem;

    max-width: f.rem(1200);
  }

  @include m.breakpoint(sm) {
    --sx: 5rem;

    padding: 0 1rem;
  }
}
