@use "../abstracts" as *;

:root {
  --navbar-height: #{rem(82)};
  --footer-height: #{rem(87)};
}

.pt-nav {
  padding-top: var(--navbar-height);
}

@include breakpoint(xxl) {
  :root {
    // --navbar-height: #{rem(68)};
    --footer-height: #{rem(40)};
  }
}
