@use "../abstracts" as *;

.whatsapp {
  width: 4rem;
  height: 4rem;
  position: fixed;
  right: 2rem;
  bottom: var(--footer-height);
  border-radius: 50%;
  transform: translate3d(0, 25%, 0);
  z-index: 5;
  background-color: #00a159;

  @include pseudo() {
    mask: url("../img/icon-whatsapp.png") no-repeat center / 60% auto;
    background-color: var(--clr-neutral-100);
  }
}
