@use "../abstracts" as *;

.s-companies {
  background: url("../img/companies-bg.jpg") no-repeat center / cover;

  .card {
    max-width: rem(300);
    margin: 0 auto;
    background-color: var(--clr-neutral-100);

    &:hover {
      background-color: var(--clr-neutral-100);
    }

    img {
      pointer-events: none;
      user-select: none;
    }

    &-body {
      padding: 2rem;
    }
  }

  .swiper {
    &-button {
      &-prev,
      &-next {
        position: relative;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: 0;
      }
    }
  }

  @include breakpoint(sm) {
    .card {
      max-width: rem(200);

      &-body {
        padding: 1rem;
      }
    }
  }
}
