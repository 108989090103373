@use "../abstracts" as *;

.c-video {
  position: relative;
  box-shadow: -1rem 1rem 1rem rgb(25 25 25 / 0.5);

  &__play {
    display: grid;
    place-items: center;
    width: rem(120);
    height: rem(120);
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid var(--clr-neutral-100);
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: transform 0.15s;
    background: none;
    color: var(--clr-neutral-100);
    font-size: 2rem;

    i {
      display: grid;
      place-items: center;
      width: rem(34);
      height: rem(34);
    }

    &:hover {
      transform: translate3d(-50%, -50%, 0) scale(1.035);
    }

    &:active {
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &__video {
    width: 100%;
    max-height: rem(768);
    object-fit: contain;
  }

  @include breakpoint(xxl) {
    &__play {
      width: rem(75);
      height: rem(75);
    }
  }

  @include breakpoint(sm) {
    &__play {
      width: rem(50);
      height: rem(50);
      font-size: 1.5rem;
    }
  }
}
