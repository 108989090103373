@use "../abstracts" as *;

.s-contact {
  min-height: calc(100vh - var(--footer-height));
  background: var(--clr-primary) url("../img/primary-bg.png") no-repeat center / cover;

  .l-container {
    max-width: rem(1440) !important;
    width: 100%;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  p {
    font-size: rem(30);
  }

  &__icons {
    gap: 2rem 0;
    margin-bottom: 3rem;
    line-height: 1.2;
    font-size: rem(23);

    > * {
      color: inherit;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__icon {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: var(--clr-neutral-0);
    mask: url("../img/card-shape-base.png") no-repeat center / contain;
    aspect-ratio: 1 / 1.17;

    img {
      width: 2rem;
      height: 2rem;
      object-fit: contain;
    }
  }

  .form-control {
    border: 1px solid hsla(0, 0%, 67%, 0.35);
    border-radius: 0;
    background-color: hsla(0, 0%, 92%, 0.35);

    &::placeholder {
      color: white;
    }
  }

  .form-row {
    margin: 0;
    gap: 1.75rem;
    flex-wrap: nowrap;
  }

  .form-group {
    flex: 1;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  .btn {
    font-size: rem(27);
  }

  @include breakpoint(xxl) {
    h2 {
      margin-bottom: 0.25em;
    }

    .l-container {
      max-width: rem(1100) !important;
      padding-bottom: 1rem !important;
    }

    p {
      font-size: rem(20);
    }

    &__icons {
      margin-bottom: 1rem;
      font-size: rem(16);

      a {
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__icon {
      display: grid;
      place-items: center;
      width: max-content;
      margin: 0 auto 0.5rem;
      padding: 0.75rem;

      img {
        width: 1.75rem;
        height: 1.75rem;
      }
    }

    .btn {
      margin-top: 1.5rem !important;
      font-size: rem(19);
    }
  }

  @include breakpoint(md) {
    &__icons {
      flex-direction: column;
    }

    .form-row {
      gap: 0;
      flex-direction: column;
    }
  }

  @include breakpoint(sm) {
    h2 {
      margin-bottom: 0.5em;
    }

    &__icon {
      padding: 0.75rem;

      img {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}
