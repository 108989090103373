@use "../abstracts" as *;

:root {
  --clr-primary-hue: 33;
  --clr-primary-saturation: 91%;
  --clr-primary-light: 57%;

  --clr-primary: hsl(33, 91%, 57%);
  --clr-primary-hover: hsl(33, 91%, 52%);
  --clr-primary-active: hsl(33, 91%, 47%);

  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      --clr-#{$color}-#{$shade}: #{$value};
    }
  }
}
