@font-face {
  font-family: "Nexa";
  src: url("../fonts/Nexa Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Nexa";
  src: url("../fonts/Nexa Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Thin.ttf");
  font-weight: 100;
}
