@use "../abstracts" as *;

.s-preview {
  background: url("../img/preview-bg.jpg") no-repeat center / cover;
  overflow: scroll;

  &__cards {
    --card-width: 25rem;

    .col {
      flex: 0;
    }
  }

  .card {
    width: var(--card-width);
  }

  #card-bottom {
    margin-top: -5.5rem;
    margin-left: calc(var(--card-width) * 1);
    transform: translate3d(-50%, 0, 0);
  }

  &__swiper {
    display: none;
    max-width: 100%;

    .card {
      width: 100%;
      max-width: 20rem;
      margin: 0 auto;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 32%;
  }

  @include breakpoint(xxl) {
    &__cards {
      --card-width: 17rem;

      .row {
        margin-right: -0.4rem;
        margin-left: -0.4rem;
      }

      .col {
        padding: 0 0.4rem;
      }
    }

    #card-bottom {
      margin-top: -4.25rem;
    }
  }

  @include breakpoint(lg) {
    &__cards {
      --card-width: 20rem;
    }
  }

  @include breakpoint(xl) {
    &__swiper {
      display: block;
    }

    &__card-out-slider {
      display: none;
    }
  }
}
