@use "../abstracts" as *;

.l-header {
  transition: background-color 0.4s;

  &--dark {
    background-color: rgba(0, 0, 0, 0.7);

    + .main-swiper .main-swiper__pagination {
      .swiper {
        &-pagination {
          &-bullet {
            &-active {
              background-color: #fff;
              color: var(--clr-primary);
            }
          }
        }
      }
    }
  }

  &--actived {
    background-color: black;
  }
}

.navbar {
  &-brand {
    max-width: rem(300);
    overflow: hidden;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      max-height: calc(var(--navbar-height) - 1rem);
      object-fit: contain;
    }
  }

  &-nav {
    gap: 0.5rem;
  }
}

.nav {
  &-link {
    font-size: rem(28);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--clr-neutral-100) !important;

    &:hover {
      color: var(--clr-neutral-100);
    }
  }
}

@include breakpoint(xxl) {
  .nav {
    &-link {
      font-size: rem(22);
    }
  }
}

@include breakpoint(sm) {
  .navbar {
    &-nav {
      gap: 0.5rem;
    }
  }
}
