@use "../abstracts" as *;

body {
  font-family: "Nexa", sans-serif;
  color: var(--clr-neutral-10);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
.h1 {
  font-size: rem(80);
}

h2,
.h2 {
  margin-bottom: 0.5em;
  font-size: rem(72);
}

h3,
.h3 {
  font-size: rem(56);
}

p {
  font-size: rem(25);
  line-height: 1.5;
}

.form-control {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: rem(24);
}

@include breakpoint(xxl) {
  h1,
  .h1 {
    font-size: rem(154);
  }

  h2,
  .h2 {
    font-size: rem(48);
  }

  h3,
  .h3 {
    font-size: rem(34);
  }

  p {
    font-size: rem(19);
  }

  .form-control {
    font-size: rem(16);
  }
}

@include breakpoint(sm) {
  h1,
  .h1 {
    font-size: rem(58);
  }

  h2,
  .h2 {
    font-size: rem(36);
  }

  h3,
  .h3 {
    font-size: rem(28);
  }

  p {
    font-size: rem(18);
  }

  .form-control {
    font-size: rem(16);
  }
}
