@use "../abstracts" as *;

.container {
  &-xxl {
    max-width: rem(1920);
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
  }
}

@include breakpoint-up(md) {
  .container {
    &-xxl {
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }
}
