@use "../abstracts" as *;

.btn {
  border-radius: 0.75em;
  font-weight: 700;
  user-select: auto;

  &--lg {
    padding: 0.75rem 4rem;
    font-size: rem(23);
  }

  &--primary {
    background-color: var(--clr-primary);
    border-color: var(--clr-primary);
    color: var(--clr-neutral-100);

    &:hover {
      background-color: var(--clr-primary-hover);
      border-color: var(--clr-primary-hover);
      color: var(--clr-neutral-100);
    }

    &:active {
      background-color: var(--clr-primary-active);
      border-color: var(--clr-primary-hover);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem
        hsl(var(--clr-primary-hue) var(--clr-primary-saturation) var(--clr-primary-light) / 25%);
    }
  }

  &--dark {
    padding: 1em 1.25em;
    line-height: 1;
    border-color: var(--clr-neutral-0);
    background-color: var(--clr-neutral-0);
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    color: var(--clr-neutral-100);

    &:hover {
      background-color: var(--clr-neutral-10);
      border-color: var(--clr-neutral-10);
      color: var(--clr-neutral-100);
    }

    &:active {
      background-color: var(--clr-neutral-20);
      border-color: var(--clr-neutral-20);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(0, 0, 0, 0.25);
    }
  }

  @include breakpoint(sm) {
    &--lg {
      padding: 0.75rem 1rem;
      font-size: rem(18);
    }
  }
}
